<template>
  <v-sheet class="ma-2" rounded>
    <v-container fluid>
      <h4>{{title}}</h4>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row  class="mt-5">
          <v-col class="pb-0">
            <v-select
              v-model="form.transportModa"
              :rules="rules.transportModa"
              :label="$_strings.locations.TYPE_LOCATION"
              :items="itemsLocationType"
              clearable
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col>
            <v-text-field
              v-mask="{'alias': 'no-special-characters-allowed'}"
              v-model="form.name"
              :label="$_strings.transportTypes.NAME_INPUT_LABEL"
              :rules="rules.name"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formLength"
              :label="$_strings.transportTypes.LENGTH_INPUT_LABEL"
              :rules="rules.length"
              v-mask="{'mask': '9', 'repeat': 10, 'greedy' : false}"
              suffix="cm"
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formWidth"
              :label="$_strings.transportTypes.WIDTH_INPUT_LABEL"
              :rules="rules.width"
              v-mask="{'mask': '9', 'repeat': 10, 'greedy' : false}"
              suffix="cm"
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="formHeight"
              :label="$_strings.transportTypes.HEIGHT_INPUT_LABEL"
              :rules="rules.height"
              v-mask="{'mask': '9', 'repeat': 10, 'greedy' : false}"
              suffix="cm"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              disabled
              v-model="form.volume"
              :label="$_strings.transportTypes.VOLUME_INPUT_LABEL"
              :rules="rules.volume"
              suffix="m³"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formMaxWeight"
              :label="$_strings.transportTypes.MAXWEIGHT_INPUT_LABEL"
              :rules="rules.maxWeight"
              v-mask="{'alias': 'decimal',rightAlign: false}"
              suffix="ton"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.avgSpeed"
              :label="$_strings.transportTypes.AVGSPEED_INPUT_LABEL"
              :rules="rules.avgSpeed"
              v-mask="{'alias': 'decimal',rightAlign: false}"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" v-if="!form.imageUrl">
            <v-file-input
              :label="$_strings.transportTypes.IMAGE_INPUT_LABEL"
              :clearable="false"
              :rules="rules.imageUrl"
              ref="imageInput"
              type="file"
              accept="image/*"
              @change="setFileInput"
              v-model="formUpload.file"
            />
          </v-col>
          <v-col :cols="12" sm="4" v-else>
            <v-card
              class="mx-auto"
              outlined
            >
              <v-img
                :src="form.imageUrl"
                contain
                aspect-ratio="1"
                height="200"
                lazy-src="../../assets/images/placeholder-image.png"
              >
              </v-img>
              <v-card-actions class="white">
                <v-btn
                  color="red"
                  small
                  block
                  class="pa-4 white--text"
                  @click="deleteFile"
                  :loading="formUpload.isLoading"
                >
                  Hapus Foto
                  <v-icon
                    right
                    dark
                  >
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-btn @click="$router.go(-1)">
              {{$_strings.common.BACK}}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              color="success"
              @click="submit"
            >
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: 'TransportTypesCreate',
  created() {
    const { data } = this.$route.params;
    if (data) {
      this.form = data;
    } else if (this.transportTypeId) {
      this.fetchTransportTypesDetail();
    }
  },
  beforeDestroy() {
    if (this.fileQueueDeletion.length && this.isSubmited) {
      Promise.all(this.fileQueueDeletion.map((payload) => this.$_services.uploads.deleteFile(payload)));
    }
  },
  data() {
    return {
      valid: false,
      form: {
        transportModa: '',
        name: '',
        length: 0,
        width: 0,
        height: 0,
        volume: 0,
        maxWeight: 0,
        imageUrl: '',
        avgSpeed: '',
        isActive: true,
      },
      rules: {
        transportModa: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.locations.TYPE_LOCATION),
        ],
        name: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transportTypes.NAME_INPUT_LABEL),
        ],
        length: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transportTypes.LENGTH_INPUT_LABEL),
        ],
        width: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transportTypes.WIDTH_INPUT_LABEL),
        ],
        height: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transportTypes.HEIGHT_INPUT_LABEL),
        ],
        maxWeight: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transportTypes.MAXWEIGHT_INPUT_LABEL),
        ],
        avgSpeed: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.transportTypes.AVGSPEED_INPUT_LABEL),
        ],
        imageUrl: [
          (v) => !v || v.size < 500000 || this.$_strings.messages.validation.IMAGE_TOO_LARGE,
          (v) => !!this.form.imageUrl || this.$_strings.messages.validation.REQUIRED(this.$_strings.transportTypes.IMAGE_INPUT_LABEL),
        ],
      },
      itemsLocationType: [
        {
          text: 'Laut',
          value: 'SEA',
        },
        {
          text: 'Udara',
          value: 'AIR',
        },
        {
          text: 'Darat',
          value: 'LAND',
        },
      ],
      formUpload: {
        fileName: '',
        isLoading: false,
        file: null,
        base64: null,
      },
      fileQueueDeletion: [],
      isSubmited: false,
    };
  },
  computed: {
    title() {
      return this.transportTypeId ? this.$_strings.transportTypes.EDIT_TRANSPORTTYPES_PAGE_NAME : this.$_strings.transportTypes.CREATE_TRANSPORTTYPES_PAGE_NAME;
    },
    transportTypeId() {
      return this.$route.params.id;
    },
    isEditing() {
      return !!this.transportTypeId;
    },
    calculatedVolume() {
      const { length, height, width } = this.form;
      return (length * height * width) / 1000000;
    },
    formLength: {
      get() {
        return this.form.length;
      },
      set(newVal) {
        if (+String(newVal).charAt(0) === 0) {
          this.form.length = +String(newVal).charAt(1);
          return;
        }
        this.form.length = newVal;
      },
    },
    formWidth: {
      get() {
        return this.form.width;
      },
      set(newVal) {
        if (+String(newVal).charAt(0) === 0) {
          this.form.width = +String(newVal).charAt(1);
          return;
        }
        this.form.width = newVal;
      },
    },
    formHeight: {
      get() {
        return this.form.height;
      },
      set(newVal) {
        if (+String(newVal).charAt(0) === 0) {
          this.form.height = +String(newVal).charAt(1);
          return;
        }
        this.form.height = newVal;
      },
    },
    formMaxWeight: {
      get() {
        return this.form.maxWeight;
      },
      set(newVal) {
        if (+String(newVal).charAt(0) === 0) {
          this.form.maxWeight = +String(newVal).charAt(1);
          return;
        }
        this.form.maxWeight = newVal;
      },
    },
  },
  watch: {
    calculatedVolume(newVal, oldVal) {
      this.form.volume = newVal;
    },
  },
  methods: {
    fetchTransportTypesDetail() {
      this.$root.$loading.show();
      this.$_services.transportTypes.fetchTransportTypesDetail(this.transportTypeId)
        .then((result) => {
          this.form = result.data;
        }).finally((e) => {
          this.$root.$loading.hide();
        });
    },
    fileUploadValidation(event, fileSizeMin = 500000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 500000 => 500kb
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > 500000) {
        this.$dialog.notify.error('Gambar Maksimum 500kb.');
        return false;
      }
      return true;
    },
    setFileInput(file) {
      const valid = this.fileUploadValidation(file);
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;
        this.formUpload.base64 = base64Image;
        this.formUpload.fileName = file.name;
        this.form.imageUrl = base64Image;
      };
      reader.readAsDataURL(file);
    },
    async deleteFile() {
      if (!this.formUpload.base64 && this.form.imageUrl) {
        try {
          this.formUpload.isLoading = true;
          const form = {
            url: this.form.imageUrl,
          };
          this.fileQueueDeletion.push(form);
          this.form.imageUrl = null;
        } finally {
          this.formUpload.isLoading = false;
        }
      } else {
        this.form.imageUrl = null;
        this.formUpload.fileName = null;
        this.formUpload.file = null;
        this.formUpload.base64 = null;
      }
    },
    uploadImage() {
      this.uploadingImage = true;
      const filePath = `transport_types/${this.form.name}/`;
      const { base64, fileName } = this.formUpload;
      return this.$_services.uploads.uploadImage(base64, fileName, filePath)
        .then((result) => {
          this.form.imageUrl = result.data.url;
          this.formUpload.file = null;
          if (this.fileQueueDeletion.length && this.fileQueueDeletion[0].url === result.data.url) {
            this.fileQueueDeletion = [];
          }
        });
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      if (this.formUpload.base64) {
        try {
          this.$root.$loading.show();
          await this.uploadImage();
        } catch {
          this.$root.$loading.hide();
          return;
        }
      }
      this.$root.$loading.show();
      this.$_services.transportTypes.createEditTransportType(this.form, this.transportTypeId)
        .then((res) => {
          this.isSubmited = true;
          this.$dialog.notify.success(this.transportTypeId ? this.$_strings.transportTypes.SUCCESS_EDIT_TRANSPORTTYPES_MESSAGE_TEXT : this.$_strings.transportTypes.SUCCESS_CREATE_TRANSPORTTYPES_MESSAGE_TEXT);
          this.$router.go(-1);
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
  },
};
</script>

<style>
</style>
